<template>
    <div id="template" class="relative sm:w-full md:w-full lg:w-full mx-auto self-center">
        <div id="verifyEmail" class="relative sm:w-full md:w-full lg:w-full mx-auto self-center">
            <div class="vx-col w-full lg:w-full mb-base">
                <vx-card slot="no-body" class="text-center bg-white greet-user">
                    <img src="@/assets/images/elements/decore-left.png" class="decore-left" alt="Decore Left"
                        width="200">
                    <img src="@/assets/images/elements/decore-right.png" class="decore-right" alt="Decore Right"
                        width="175">
                    <feather-icon icon="UserIcon" class="p-6 mb-8 bg-primary inline-flex rounded-full text-white shadow"
                        svg-classes="h-8 w-8"></feather-icon>
                    <h1 class="mb-6 text-primary">Hello {{ activeUserInfo.name || '!' }},</h1>
                    <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-primary">Welcome and enjoy.</p>
                </vx-card>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {

            }
        },
        computed: {
            activeUserInfo: function () {
                return (!this.$store.state.auth.user) ? '' : JSON.parse(this.$store.state.auth.user)
            }
        },
        mounted() {
          
        },
        methods: {
            
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/vuesax/_variables.scss";

    #template {
        .floating-button {
            font-size: 10px;
            padding: 10px;
            position: fixed;
            bottom: 50px;
            right: 30px;
        }

        .header {
            padding-top: 20px;
            margin-bottom: 40px;

            .title {
                color: #444444;
                font-weight: 600;
                font-size: 1.3rem;
                line-height: 1;
            }
        }

        .body {
            .flex {
                flex: 1;
                justify-content: flex-end;

                .small-btn {
                    border-radius: 0 !important;
                }
            }

            .decore-left {
                position: absolute;
                left: 0;
                top: 0;
            }

            .decore-right {
                position: absolute;
                right: 0;
                top: 0;
            }

        }

        #verifyEmail {
            .greet-user {
                position: relative;

                .decore-left {
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                .decore-right {
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
    }
</style>